import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"

import ReactMarkdown from "react-markdown/with-html"
import Moment from "react-moment"

import Layout from "../components/layout"
import { renderers } from "../utils/markdown"
import LinkLabel from "../components/link-label"

export const query = graphql`
  query ArticleQuery($id: Int!) {
    strapiArticle(strapiId: { eq: $id }) {
      strapiId
      title
      content
      published_at
      author {
        id
        username
      }
      category {
        id
        name
      }
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiArticle
  return (
    <Layout>
      <div className="uk-section">
        <div className="uk-container uk-container-small">
          <article className="uk-article">
            <h1 className="uk-article-title">
              <a className="uk-link-reset" href="javascript:void(0)">
                {article.title}
              </a>
            </h1>

            <p className="uk-article-meta">
              Written by{" "}
              <LinkLabel to={`/author/${get(article, "author.id")}`}>
                {get(article, "author.username")}
              </LinkLabel>{" "}
              on <Moment format="MMM Do YYYY">{article.published_at}</Moment>.
              Posted in{" "}
              <LinkLabel to={`/category/${get(article, "category.id")}`}>
                {get(article, "category.name")}
              </LinkLabel>
            </p>

            <ReactMarkdown
              source={article.content}
              renderers={renderers}
              escapeHtml={false}
            />
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default Article
